import * as actionTypes from './../actions/actionTypes';
import produce from 'immer';
import _ from 'lodash';

const INITIAL_STATE = {
  data: {
    initial_search: {
      country: [],
    },
    advanced_search: [],
    stages: [],
  },

  search_params: {
    initialSearch: {
      country: [],
      date: ['', ''],
      process: [],
    },
    advancedSearch: {},
    stages: {topics: []},
    keyword: '',
    removed_processes: {},
  },
  //search_results: {},
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOAD_JSON_FILE_SUCCESS: {
      console.log(action.payload);
      return produce(state, (draft) => {
        //draft.data = action.payload;

        const {initial_search, advanced_search} = action.payload;
        Object.keys(initial_search).forEach((index) => {
          console.log(index);
          draft.data.initial_search[index] = initial_search[index];
          draft.search_params.initialSearch[index] = [];
        });

        advanced_search.forEach((filter, index) => {
          if (filter.level_of_violence) {
            filter.level_of_violence.forEach(violence => {
              violence.value = Math.max(0, violence.value - 1); // Ensure no negative values
            });
          }


          if (filter.direct_international_support) {
            filter.direct_international_support.forEach(violence => {
              violence.value = Math.max(0, violence.value - 1); // Ensure no negative values
            });
          }

          const filterKey = Object.keys(filter)[0];
          if (filterKey !== 'stages') {
            draft.search_params.advancedSearch[filterKey] = [];
          }
        });

        const stagesKey = advanced_search.length - 1;
        console.log([...advanced_search].splice(stagesKey));
        draft.data.stages = [...advanced_search].splice(stagesKey)[0].stages;

        const advanced_search_data = [...advanced_search].splice(0, stagesKey);
        draft.data.advanced_search = advanced_search_data;
      });
    }
    case actionTypes.CLEAR_ALL_FILTERS:
      return produce(state, (draft) => {
        draft.search_params = INITIAL_STATE.search_params;
      });
    case actionTypes.SEARCH_PARAMS_CHANGED:
      console.log('SEARCH_PARAMS_CHANGED', action.payload);
      return produce(state, (draft) => {
        if (action.payload.field === 'date') {
          draft.search_params.initialSearch.date = action.payload.value;
        } else if (action.payload.field === 'keyword') {
          draft.search_params.keyword = action.payload.value;
        } else if (action.payload.subfield === 'process') {
          draft.search_params.initialSearch.process = _.flatten(
            action.payload.value,
          );
        } else if (action.payload.subfield) {
          draft.search_params[action.payload.field][action.payload.subfield] =
            action.payload.value;
        } else draft.search_params[action.payload.field] = action.payload.value;
      });
    case actionTypes.REMOVE_SEARCH_RESULT:
      return produce(state, (draft) => {
        draft.search_params.removed_processes[action.process.id] =
          action.process;
      });
    case actionTypes.UNDO_REMOVED_SEARCH_RESULT:
      return produce(state, (draft) => {
        delete draft.search_params.removed_processes[action.id];
      });
    /*
      return {
        ...state,
        search_params: {
          ...state.search_params,
          [action.payload.field]: {
            ...state.search_params[action.payload.field],
            [action.payload.subfield]: action.payload.value,
          },
        },
      }; */

    default:
      return state;
  }
}
