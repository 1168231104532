import {all, takeEvery, put, fork} from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions/index';
import * as API from '../api';
import {gaTrackDownload} from '../../utils/helper';

export function* exportExcel() {
  yield takeEvery(actionTypes.EXPORTING_EXCEL, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.exportExcel(action.id);

      if (response.data) {
        //Create a Blob from the Excel Stream
        const file = new Blob([response.data], {type: 'application/xml'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        // window.open(fileURL);

        const index = fileURL.lastIndexOf('/');
        const fileName = fileURL.substring(index + 1);

        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', `${fileName}.xlsx`);
        document.body.appendChild(link);
        link.click();

        let data = {};
        if (action?.processName) {
          data.processName = action.processName;
        }
        gaTrackDownload('excel', data);
      }
      yield put(actions.loading(false));
    } catch (error) {
      console.log('exportExcel', '.catch', error);
      yield put(actions.loading(false));
    }
  });
}

export function* exportPDF() {
  yield takeEvery(actionTypes.EXPORT_TO_PDF, function* (action) {
    yield put(actions.loading(true));

    try {
      const response = yield API.exportPDF(action.data);
      if (response.data) {
        // console.log('xxx', response.data);
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {type: 'application/pdf'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        // window.open(fileURL);

        const index = fileURL.lastIndexOf('/');
        const fileName = fileURL.substring(index + 1);

        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', `${fileName}.pdf`);
        document.body.appendChild(link);
        link.click();

        const pinboard = action?.data?.pinboard;
        const type = pinboard?.summary === true ? 'pdf_summary' : 'pdf_full';
        const data = {
          process: pinboard?.process,
        };
        gaTrackDownload(type, data);
      }
      yield put(actions.loading(false));
    } catch (error) {
      console.log('xxx', error);
      yield put(actions.loading(false));
    }
  });
}

export default function* globalSaga() {
  yield all([fork(exportExcel), fork(exportPDF)]);
}
