import { useRef, useEffect } from 'react';
import _ from 'lodash';
import produce from 'immer';
// import Regex from './regex';

export const _findOption = (list, id) => {
  return list.find((option) => String(option.value) === String(id));
};

export const _findArrayOption = (list, selected) => {
  let selectedOption = [];
  selected &&
    selected.forEach((id) => {
      let listOption = list.find(
        (option) => String(option.value) === String(id),
      );
      if (listOption) {
        selectedOption.push(listOption);
      }
    });
  return selectedOption;
};

export const _findArrayNestedOption = (list, selected) => {
  let selectedOption = [];
  selected &&
    selected.forEach((id) => {
      list.forEach((currentOption) => {
        let listOption = currentOption.options.find(
          (option) => String(option.value) === String(id),
        );
        if (listOption) {
          selectedOption.push(listOption);
        }
      });
    });
  return selectedOption;
};

export const _searchParamCount = (params) => {
  let paramsCount = 0;
  if (params !== 'all') {
    if (
      params.keyword !== '' ||
      (params.stages.topics && params.stages.topics.length > 0)
    ) {
      paramsCount = 1;
      return paramsCount;
    }

    // Check if initial search has any params
    Object.keys(params.initialSearch).forEach((val, key, o) => {
      // console.log(key, val, val.length);

      if (val.length > 0) {
        if (key !== 'date') {
          paramsCount = 1;
          return paramsCount;
        } else if (val[0] !== '' || val[1] !== '') {
          paramsCount = 1;
          return paramsCount;
        }
      }
    });

    // Check if advanced search has any params
    Object.keys(params.advancedSearch).forEach((val, key, o) => {
      if (val.length > 0) {
        paramsCount = 1;
        return paramsCount;
      }
    });
  }
  return paramsCount;
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
/*
  Example : _difference(this.currentAddresscart, prevProps.cart)
  Performance is greater than JSON.stringify compare
*/
export const _difference = (object, base) => {
  return _.transform(object, (result, value, key) => {
    if (!_.isEqual(value, base[key])) {
      result[key] =
        _.isObject(value) && _.isObject(base[key])
          ? _.difference(value, base[key])
          : value;
    }
  });
};

export const _objectIsEqual = (object, base) => {
  return _.isEmpty(_difference(object, base));
};

export const _buildFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob)
  ) {
    Object.keys(data).forEach((key) => {
      _buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
      );
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
};

export const _filterCountry = (candidate, input) => {
  if (input) {
    // Check country name is match
    let label = candidate.label;

    if (candidate.data.options) {
      label = candidate.label.split('|')[0];
    } else if (candidate.data.group) {
      label = candidate.data.group.split('|')[0];
    }

    if (_.toLower(label).indexOf(_.toLower(input)) !== -1) {
      return true;
    }
    // Else if country alternative name is match
    else if (
      candidate.data.options &&
      candidate.data.alternate_names !== null &&
      candidate.data.alternate_names.length > 0
    ) {
      const { alternate_names } = candidate.data;
      let index = 0;
      for (index; index < alternate_names.length; index++) {
        if (
          _.toLower(alternate_names[index]).indexOf(_.toLower(input)) !== -1
        ) {
          return true;
        }
      }
    }
    return false;
  }
  return true;
};

export const _filterCountryYearsWithProcess = (
  countryList,
  selectedCountry,
  selectedYears,
) => {
  if (selectedCountry.length === 0) {
    return [];
  }

  let yearList = {};
  let selectedCountryIndex = 0;
  for (
    selectedCountryIndex;
    selectedCountryIndex < selectedCountry.length;
    selectedCountryIndex++
  ) {
    const country_id = selectedCountry[selectedCountryIndex];

    const country = _.find(countryList, (country) => {
      return country.id === country_id;
    });

    if (country && country.years && country.years.length > 0) {
      let countryYearIndex = 0;
      for (
        countryYearIndex;
        countryYearIndex < country.years.length;
        countryYearIndex++
      ) {
        const processYear = country.years[countryYearIndex];
        const { year, process_id } = processYear;

        // If selected , do not show at option
        const isSelected = _.findIndex(selectedYears, (processYear) => {
          return parseInt(processYear) === parseInt(process_id);
        });
        if (selectedYears !== undefined && isSelected !== -1) {
          continue;
        }

        if (yearList[year] === undefined) {
          yearList = {
            ...yearList,
            [year]: [process_id],
          };
        } else {
          yearList = {
            ...yearList,
            [year]: [...yearList[year], process_id],
          };
        }
      }
    }
  }

  if (Object.keys(yearList).length > 0) {
    const yearOptions = produce([], (draft) => {
      Object.keys(yearList).forEach((key) => {
        const label = key;
        const value = yearList[key];

        draft.push({ label, value });
      });
    });
    return yearOptions;
  }

  return [];
};

export const useDeepEffect = (effectFunc, deps) => {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isEqual = _objectIsEqual(prevDeps.current, deps);

    if (isFirst.current || !isEqual) {
      effectFunc();
    }

    isFirst.current = false;
    prevDeps.current = deps;
  }, deps);
};

export const gaTrackDownload = (type, extraData = {}) => {
  if (window?.dataLayer) {
    /**
     * event: 'file_download'
     * type: 'pdf_summary' | 'pdf_full' | 'excel' | 'excel_full_dataset'
     * pageUrl: 'https://example.com/process/123'
     * extraData: {
     *  // individual process info
     *  processName: 'Afghanistan 2024',
     *
     *  // OR multiple process info
     *  process: []
     * }
     */
    const data = {
      event: 'file_download',
      type,
      pageUrl: window.location.href,
      ...extraData,
    };
    window.dataLayer.push(data);
  }
};
